window.addEventListener('heyflow-init', (event) => {
    const currentPostData = localStorage.getItem('currentPostData');
    if (currentPostData !== null) {
        localStorage.removeItem(currentPostData);
    }
});

window.addEventListener('heyflow-screen-view', (event) => {
    const apiUrl = 'https://api.babbel.io/gamma/v1/events';
    const currentScreen = event.detail.screenIndex;
    const getCookieValue = (name) => (document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '');
    const trackingUuid = getCookieValue('babbeltrackinguuid');
    const postData = {
        events: [
            {
                created_at: new Date().toISOString(),
                origin: 'flow.babbel',
                provider: 'flow.babbel',
                tracking_uuid: trackingUuid,
                name: 'welcome_page:shown',
                locale: 'en',
                learn_language_alpha3: 'MUL',
                version: 3,
                category: "HeyFlow-PrivateClasses-EN",
                current_slide: currentScreen,
                skip_interstitial: false,
                skip_questions: false,
                skip_value_props: false
            }
        ]
    };
    const stringifiedPostData = JSON.stringify(postData);
    navigator.sendBeacon(apiUrl, stringifiedPostData);
});

window.addEventListener('heyflow-change', (event) => {
    const currentQuestion = event.detail.fields.at(-1).screenName;
    const currentAnswer = event.detail.fields.at(-1).value;
    const getCookieValue = (name) => (document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '');
    const trackingUuid = getCookieValue('babbeltrackinguuid');
    const postData = {
        events: [
            {
                created_at: new Date().toISOString(),
                origin: 'flow.babbel',
                provider: 'flow.babbel',
                tracking_uuid: trackingUuid,
                name: 'question:answered',
                locale: 'en',
                learn_language_alpha3: 'MUL',
                version: 5,
                question: currentQuestion,
                answer: currentAnswer,
                answer_presentation_id: 999
            }
        ]
    };
    const stringifiedPostData = JSON.stringify(postData);
    localStorage.setItem('currentPostData', stringifiedPostData);
});

window.addEventListener('heyflow-screen-leave', (event) => {
    const apiUrl = 'https://api.babbel.io/gamma/v1/events';
    const currentPostData = localStorage.getItem('currentPostData');
    if (currentPostData !== null) {
        navigator.sendBeacon(apiUrl, currentPostData);
        localStorage.removeItem('currentPostData');
    } else {
        localStorage.removeItem(currentPostData);
    }
});